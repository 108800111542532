.accountField-ContainerFluid {
  padding: 2.5% 2% 0 4% !important;

  .locleft {
    margin-bottom: 34px;
  }

  .locationCommonDiv {
    padding: 0;
  }
  .sample-header {
    display: none;
  }
  .glyph-icon {
    position: relative;
    top: 4px;
    font-style: normal;
    line-height: 1;
  }
  .line-location {
    width: 100%;
    display: block;
  }

  .icon {
    color: #0057a6;
    font-size: 30px;
  }

  #location-select {
    display: flex;
    padding: 0.6875em 0.875em 0.6875em 0.875em !important;
    background: white;
    box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    -webkit-box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    -moz-box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    min-width: 360px;
    border-radius: 4px;
  }

  .contact-details h3,
  h4 {
    color: #2a343f;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 1.16em;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin: 1.25em 0 0 0;
    font-weight: 600;
  }
  .contact-details h4 {
    font-size: 1.1em;
    color: #747881;
  }

  button:focus,
  .btn:focus {
    outline-style: none;
    outline-color: var(--cx-color-visual-focus);
    outline-width: var(--cx-visual-focus-width, 0.125em);
    outline-offset: 0.25em;
    transition: none;
  }

  hr {
    margin: 0.625em -0.9375em;
    box-shadow: 0 0.0625em 0.125em rgba(23, 114, 183, 0.2);
    -webkit-box-shadow: 0 0.0625em 0.125em rgba(23, 114, 183, 0.2);
    -moz-box-shadow: 0 0.0625em 0.125em rgba(23, 114, 183, 0.2);
    -ms-box-shadow: 0 0.0625em 0.125em rgba(23, 114, 183, 0.2);
    clear: both;
  }
}
.modal-account-location .modal-body {
  padding: 12px 20px 19px;
  min-width: 40vw;
}

.scroll-data {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
}

.custom-control {
  min-height: auto;
}
.modal-account-location .modal-header {
  display: block;
  background: #edf6fb;
  height: 86px;
  padding: 10px;
  border: 0;
  width: 460px;
  width: 100% !important;
}

.location-done {
  background: #0057a6;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  opacity: 1;
  width: 100%;
  height: 2.75em;
  outline: 0;
  border: 0;
}

// input text button

.searchboxnew {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.input-group-btn {
  display: flex;
  max-height: 44px;
}
.input-group {
  border-collapse: collapse;
}
.loc-SearchBar input {
  border: 0;
  box-shadow: none;
  background: none;
  width: 100%;
  font-size: 14px;
  height: 44px;
  margin-bottom: 0;
  padding: 0.3125em 0.75em;
}
.loc-SearchBar button.btn {
  border: 0;
  font-size: 1em;
  color: #0057a6;
  background: none;
  outline: none;
  box-shadow: none;
}
.loc-SearchBar {
  width: 100%;
  box-shadow: inset 0 0 2px 0px #d4d9de !important;
  border: 1px solid #d4d9de;
  border-radius: 4px;
  display: table;
  -webkit-box-shadow: inset 0 0 0.125em 0 #d4d9de;
  -moz-box-shadow: inset 0 0 0.125em 0 #d4d9de;
  float: none;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

// end search box

// custome radio

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0057a6;
  background-color: #0057a6;
}

.label-location {
  border-bottom: 0.0625em solid #e5e9ec;
  padding-bottom: 0.3125em;
}

.secloct {
  padding-left: 0 !important;
  margin-left: 0.375em !important;
  font-family: "NotoSansBold", sans-serif !important;
  margin-bottom: 0 !important;
}

.radio_grp label {
  font-family: "NotoSansBold", sans-serif !important;
  font-size: 1em;
  color: #5b646e;
  margin-left: 0.625em;
  font-weight: normal;
}
.line-location p {
  margin-bottom: 0;
}

.account-scroll-data p.labelpp {
  margin-left: 20px;
  margin-bottom: 0.5625em !important;
  padding-top: 0.3125em;
  color: #5b646e;
  font: 1em "NotoSans", sans-serif;
}

.account-scroll-data label {
  width: 95%;
  margin-top: 16px !important;
  padding-left: 0.4375em;
}

// custome model end

.preference-content {
  padding: 0 0 1.25em;
  display: inline-block;
  hr {
    margin: 20px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}

.locnewselect {
  margin-left: 2.1875em;
  width: 44.25em;
}

.locview-info {
  color: #5b646e;
  font: 12px "NotoSans", sans-serif;
  margin-bottom: 0.625em;
  margin-left: 17px;
}

#location-select .location-Details .selected-loc {
  display: block;
  font-size: 1.166em;
  font-family: "NotoSansBold", sans-serif;
  color: #2a343f;
}
#location-select .location-Details .change-loc {
  color: #0057a6;
  font: 1em "NotoSans", sans-serif;
  cursor: pointer;
}

// Start Shiping address

.post-edit .Fields-container {
  margin-top: 0.75em;
  margin-bottom: 0;
}

.Fields-container .Fields-label {
  font-family: "NotoSansBold", sans-serif;
  margin-top: 4px;
  margin-bottom: 1px;
}
.Fields-container span {
  color: #5b646e;
  font: 12px "NotoSans", sans-serif;
}
.Fields-label {
  font-size: 1em;
  color: #5b646e;
  font-family: "NotoSansBold", sans-serif;
  width: 100%;
  display: inline-block;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.3125em;
  font-weight: 700;
}

.post-edit span {
  margin-top: 0;
}

.modal-account-location .modal-title {
  text-align: center;
  margin-bottom: 0;
  line-height: 0;
}

.modal-account-location .modal-header .modal-title {
  color: #73aed3;
  margin-top: 54px;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Poppins-Medium", sans-serif;
}

.modal-account-location .modal-header .close {
  margin: -11px -17px;
  opacity: 1;
}

.modal-account-location .modal-header .close img {
  width: 22px;
  height: 22px;
  background-size: 22px;
  display: block;
}
.modal-account-location .modal-footer {
  border: 0;
  box-shadow: 0px 0 8px rgb(23 114 183 / 20%);
  -webkit-box-shadow: 0px 0 8px rgb(23 114 183 / 20%);
  -moz-box-shadow: 0px 0 8px rgba(23, 114, 183, 0.2);
  -ms-box-shadow: 0px 0 8px rgba(23, 114, 183, 0.2);
  padding: 20px;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 400px) and (-webkit-min-device-pixel-ratio: 2) {
  .accountField-ContainerFluid #location-select {
    display: flex;
    padding: 0.6875em 0.875em 0.6875em 0.875em !important;
    background: white;
    box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    -webkit-box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    -moz-box-shadow: 0 0 0.625em 0.0625em rgba(23, 114, 183, 0.2);
    min-width: 360px;
    border-radius: 4px;
  }
  .accountField-ContainerFluid {
    padding: 0;
  }
  .accountField-ContainerFluid .accnt-Div {
    padding: 0;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .accnt-Div {
    padding: 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .accountField-ContainerFluid .accnt-Div {
    padding: 0;
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) and (orientation: portrait) {
  .modal-account-location .modal-header,
  .modal-account-location .modal-body {
    width: 377px !important;
  }
}
.accountField-ContainerFluid {
  .edit-icon {
    &:hover {
      color: #337ab7;
    }
    img {
      max-width: 19px;
    }
    span {
      color: #337ab7;
    }
  }
}
