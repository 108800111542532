@import "src/app/custom-profile-and-preferences/account/account.component.scss";
.SelectVaccineSlot,
.ScheduleToShipSlot {
  hr {
    box-shadow: none;
  }
  .fluProduct-Row {
    margin: 10px 0 0 0;
  }
  .big-Device-Section {
    display: block;
  }
  .small-Device-Section {
    display: none;
  }
  .block-Click {
    pointer-events: none !important;
  }
  .activeSeasonId {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px 0px 0px 5px !important;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0px 0px 19px 0px;
    color: #337ab7;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  .Not-Available {
    color: #de1848;
    font-weight: bold;
  }
  .available-now {
    color: #0057a6 !important;
    font-weight: bold;
  }
  .modal-body-discard {
    padding: 40px 20px 19px !important;
    text-align: center;
  }
  .cancelBtn-Css {
    background: #fff;
    font-size: 1em !important;
    color: #0057a6;
    width: 100%;
    height: 40px;
    border: 2px solid #0057a6;
    outline: 0;
  }

  .yesBtn-Css {
    background: #0057a6 !important;
    color: #ffffff !important;
    border-color: #0057a6 !important;
    width: 100%;
    font-size: 1em !important;
    height: 40px;
    outline: 0;
  }
  .units-Non-Accepting {
    margin-top: 19%;
  }
  .btn:disabled {
    opacity: 0.4;
    pointer-events: none;
    background-color: #c0c0c0;
    border: 2px solid #c0c0c0;
  }
  // ====================HEADER PART CSS=========================================
  .bc-Dash-Div {
    margin: 0 0px 10px 0px;
  }
  .back-To-Dashbaord {
    color: #0057a6;
    float: right;
  }
  .back-To-Dashbaord:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .print {
    color: #0057a6;
  }
  .print:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .svg-Images {
    width: 20px;
    height: 17px;
  }
  .header-Container {
    margin: 10px 0px 10px 0;
  }
  // ====================HEADER PART CSS=========================================

  .doses-Field {
    width: 25%;
  }
  .select-Header {
    font-size: 20px;
    font-family: "Poppins-Medium", sans-serif;
    color: rgb(91, 100, 110);
    margin-left: 1%;
  }
  .select-Header-Section {
    padding: 4% 2% 2% 2%;
  }
  .select-Container {
    background: white;
    margin: 0 0 35px 0;
  }
  .bord-Right {
    border-right: 4px solid #edf6fb;
    padding-bottom: 2%;
    padding-left: 15px;
    min-height: 429px;
  }
  .prod-Image {
    width: 46px;
    background: #f4f7f9;
    cursor: pointer;
  }
  .product-Desc-Section {
    margin: 10px 0 0 0;
  }
  .prod-Name {
    font-size: 16px;
    line-height: 21px;
    color: #0057a6 !important;
    display: inline-block;
    letter-spacing: 0.5px;
    font-family: "Poppins-Medium", sans-serif;
  }
  .prod-Name:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
  .prodct-No {
    font-weight: bold;
  }
  .prod-No {
    margin-top: 5px 0 0 0;
  }

  .your-Price,
  .units-Head {
    font-size: 12px !important;
    color: #5b646e;
    font-family: "NotoSansBold", sans-serif;
  }
  .prod-Price {
    font-size: 20px;
    font-family: "Poppins-Medium", sans-serif;
    color: #2a343f;
    margin: 0 0 5px 0;
  }
  .unit-Field {
    text-align: center;
    width: 44px;
    height: 44px;
    color: #2a343f;
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    box-shadow: inset 0 0px 4px 1px #d4d9de;
  }
  .proceed-Btn {
    background: #0057a6;
    color: white !important;
    border: 2px solid #0057a6;
    text-decoration: none;
    font-size: 14px;
    font-family: "NotoSansBold", sans-serif;
    width: 100%;
    height: 44px;
    margin-top: 50px;
  }
  .proceed-Container {
    border-bottom: 4px solid #edf6fb;
    padding: 0px 15px 39px 0px;
  }
  .plus-Image {
    cursor: pointer;
  }
  .minus-Image {
    cursor: pointer;
  }
  .units-Text {
    padding-right: 10px;
    margin-left: -37px;
    margin-top: 7px;
  }
  .plus-Icon {
    padding: 10px 0px 0px 0px;
  }
  .doses-Count {
    border: none;
  }

  .proceed-small-Container {
    margin: 0px 0 8px 0;
    border-bottom: 4px solid #edf6fb;
    padding: 0 15px 13px 21px;
  }
  .displayNo {
    display: none;
  }
  .vol-Price {
    text-decoration: none !important;
    color: #337ab7 !important;
    font-size: 11px !important;
  }
  .vol-Price:hover {
    text-decoration: underline !important;
  }
  .tb tbody tr {
    background-color: #eff6fb;
    margin-bottom: 4px;
    display: block;
    margin-top: 0 !important;
    padding: 3px;
    text-align: left;
    width: 244px;
    height: 30px;
  }
  .tb tbody td {
    font-size: 12px;
  }
  .tb tbody td.green {
    color: #36a657;
  }
  .tb tbody td:first-child {
    float: left;
  }
  .tb tbody td:nth-child(2) {
    float: left;
  }
  .tb tbody td:last-child {
    float: right;
  }
  .save-price {
    color: #36a657;
  }
  .square-Container {
    border-bottom: 4px solid #edf6fb;
    min-height: 300px;
  }
  .proceed-BtnView {
    display: none;
  }
  .minus-Position {
    padding-top: 10px;
  }

  .prod-quantity {
    width: 19%;
    height: 44px;
    min-width: 45px;
    border-radius: 4px;
    border: solid 1px #d4d9de;
    box-shadow: inset 0 0px 3px 0px #d4d9e3;
    margin: 0px 6px;
  }
  .unit-area {
    cursor: pointer;
  }
  .disabled-img {
    display: none;

    cursor: auto;
  }
  .units {
    width: 100%;
    text-align: center;
  }
  .p0 {
    padding: 0px;
  }

  .proceed-Btn:hover {
    background: white;
    color: #0057a6 !important;
  }
  .volume-Pricing {
    font-size: 11px;
    color: #337ab7;
  }
  .pointer {
    cursor: pointer;
  }

  td {
    border-right: 0px solid #fff;
  }
  .banner-Section {
    min-height: 100px;
    padding: 1%;
    margin: 20px 0px 0px 0px;
    text-align: center;
  }
  @media only screen and (min-device-width: 300px) and (max-device-width: 600px) {
    .big-Device-Section {
      display: none;
    }
    .small-Device-Section {
      display: block;
    }
    .prod-Name {
      color: #0057a6;
      display: inline-block;
      letter-spacing: 0.5px;
      font-family: "Poppins-Medium", sans-serif;
    }
    .proceed-BtnView {
      display: block !important;
    }
    .proceed-BtnNormal {
      display: none;
    }
    .i-Padding {
      padding-left: 0px;
    }
    .bord-Right {
      border-bottom: 4px solid #edf6fb;
      min-height: 370px;
    }
    .bc-Dash-Div {
      margin: 2% 0% 0% 7%;
      padding: 0px;
    }
    .back-To-Dashbaord {
      float: left !important;
    }
    .print-Div {
      float: right !important;
      padding: 0px;
      margin: 2% 0% 0% 7%;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .big-Device-Section {
      display: none;
    }
    .small-Device-Section {
      display: block;
    }
    .pad-Port {
      padding: 0px 0px 0px 0px;
    }
    .proceed-BtnView {
      display: block !important;
    }
    .proceed-BtnNormal {
      display: none;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .big-Device-Section {
      display: block;
    }
    .small-Device-Section {
      display: none;
    }
    .units {
      width: 110%;
      text-align: center;
      margin-left: -30px;
    }
    .proceed-BtnNormal {
      display: block !important;
    }
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    .big-Device-Section {
      display: none;
    }
    .small-Device-Section {
      display: block;
    }
    .proceed-BtnView {
      display: block !important;
    }
    .proceed-BtnNormal {
      display: none !important;
    }
    .bc-Dash-Div {
      margin: 2% 0% 0% 7%;
      padding: 0px;
    }
    .back-To-Dashbaord {
      float: left !important;
    }
    .print-Div {
      float: right !important;
      padding: 0px;
      margin: 2% 1% 0% 7%;
    }
  }
  .pad-Port p {
    margin-bottom: 5px;
  }
  .product-Price {
    font-size: 20px;
    font-family: "Poppins-Medium", sans-serif;
    color: #2a343f;
    margin: 0 0 5px 0;
  }
  .original-price {
    opacity: 0.8;
    .product-Price {
      font-size: 16px;
    }
  }
  .row-Gap {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media only screen and (min-device-width: 600px) {
    .row-Gap {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.text-button-initial
{
  text-transform: initial;
}
