cx-asm-main-ui {
  .cx-asm-customer-list {
    display: none;
  }

  cx-asm-bind-cart form {
    display: none;
  }

  cx-customer-emulation .cx-asm-customerInfo {
    display: block;
  }
}
