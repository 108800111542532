
cx-pagination a.previous{
    background-color: #0057A6;
    border-radius: 5px;
    font-family: fantasy;
    font-size: 17px;
  }
  @media  screen and (min-width:926px){
 .mx-auto cx-pagination a.next, .mx-auto cx-pagination a.previous{
   height: 40px;
   width: 8%;
 }
 }

 @media  screen and (min-width:300px) and (max-width:650px){
  .mx-auto cx-pagination a.next, .mx-auto cx-pagination a.previous{
  height: 40px;
  width: 19%;
  }
 }
  cx-pagination a.next{
    background-color: #0057A6;
    border-radius: 5px;
    font-family: fantasy;
    font-size: 17px;
  }
  
  cx-pagination a.disabled{
    background-color: #bfc4c8;
  }
  cx-pagination a{
    color: white;
    font-size: 17px;
    font-weight: bold;
    
  }
  
  cx-pagination a:hover {
    background-color: #0057A6;
    color: white;
    font-size: 17px;
    font-weight: bold;
  }
  
  cx-pagination a {
    color: #FFFFFF;
    font-size: 17px;
    font-weight: bold;
    width: 30px;
    height: 28px;
    border: solid 1px var(--cx-color-light);
    box-sizing: content-box;
    display: flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
  }
  