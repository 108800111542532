header {
  background: none;
  background-color: white;

  & .SiteLinks cx-link cx-generic-link a {
    text-decoration: none;
  }
}

.SiteLinks {

  & cx-navigation-ui.flyout > nav > ul > li {
    & > button {
      padding: 10px;
      margin-right: 25px;
      font-size: 11px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 2px;
    }

    & > .wrapper {
      padding: 0 20px;
      border: none;
      box-shadow: 0 2px 3px 0 rgba(23, 114, 183, .2);
    }
  }

  cx-navigation-ui nav ul.childs > li > cx-generic-link > a {
    font-weight: 400;
    border-bottom: 1px solid #edf6fb;
    padding: 10px 0;
  }

  @media screen and (max-width: 767px) {
    display: flex;

    cx-navigation {
      width: 100%;

      & .accNavComponent {
        width: 100%;
      }
    }

    cx-navigation-ui nav ul.childs > li > cx-generic-link > a {
      color: #fff !important;
    }

    cx-navigation-ui.flyout.is-open li.is-open {
      background-color: #2a343f;

      & > .wrapper {
        padding: 0 10px;
        background-color: #2a343f;
      }
    }

  }

}

.SearchBox {
  cx-searchbox label input {
    font-size: 12px;
  }
}

.navigation {
  cx-navigation-ui.flyout nav ul li {
    position: relative;

    & > cx-generic-link + button {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      flex-flow: row-reverse;
    }
  }

  @media screen and (max-width: 767px) {
    cx-navigation-ui.flyout.is-open li.is-open {
      background-color: transparent;
    }
  }
}

.header .NavigationBar {

  cx-navigation-ui.flyout > nav > ul > li {
    position: relative;

    & > button {
      position: absolute;
      width: 100%;
      display: block;
      text-align: right;
      padding-top: 22px;
      padding-bottom: 12px;
    }
  }

  cx-navigation-ui {
    background-color: transparent;
    padding-bottom: 20.5px;

    & cx-generic-link {
      margin-right: 2px;

      & a {
        text-transform: none;
        font-weight: 400;
      }
    }

    & > nav > ul > li > .wrapper {
      border: none;
      box-shadow: 0 2px 3px 0 rgba(23, 114, 183, .2);
      padding: 3px 16px;
      margin: -12px -18px;
      margin-top: 53px;

      & a {
        border-bottom: 1px solid #edf6fb;
        padding: 10px 0;
      }
    }
  }
}

.BottomHeaderSlot cx-breadcrumb {
  box-shadow: none;

  & a {
    color: #337ab7;
    min-height: 0;
  }
}

@media (min-width: 768px) {
  cx-category-navigation cx-navigation-ui > nav > ul > li {
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  header.is-expanded {
    .header,
    .navigation {
      background-color: transparent;
    }

    .noDisplay {
      &.SiteLinks,
      &.SiteContext {
        display: flex !important;
      }

      &.SiteLogo,
      &.MiniCart,
      &.SearchBox {
        display: block !important;
      }
    }
  }
}
