/* Password rules section */
.password-security {
  background: #f4f7f9;
  margin: 50px 0 0;
  max-width: 300px;
  padding: 0 20px 20px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  .required-ul {
    font-size: 14px;
    list-style: disc;
    color: #5b646e;
    margin: 4px 0 0 30px;
  }

  .required-ul li {
    line-height: 18px;
  }

  .security-Notify {
    display: inline-block;
    width: 100%;
    font-family: "NotoSansBold", sans-serif;
    font-size: 12px;
    color: #5b646e;
    margin-top: 12px;
  }

  .required-ul {
    font-family: "NotoSans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #5b646e;
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .password-security {
    margin: 10px 0 0 0px;
  }
}
