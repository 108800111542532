$color-white: #fff;
$color-light-blue: #edf6fb;

.Generic-LeftContent-Slot, .Cookies-LeftContent-Slot,
.Generic-Sidebar-Slot,.Cookies-Sidebar-Slot {
  background-color: $color-white;
  padding: 30px 20px;
  width: 100%;
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
}

.Generic-LeftContent-Slot,.Cookies-LeftContent-Slot {
  border-right: 4px solid $color-light-blue;
  .return-policy-paragraph-title {
    font-family: "Poppins-Medium", sans-serif;
    font-style: italic;
    font-weight: bolder;
    margin-top:10px;
    margin-bottom:10px;
  }
  .return-policy-paragraph-text {
    font-style: italic;
  }
  .return-policy-content ul li{
    font-style: normal;
    margin-bottom:10px;
  }
  .return-policy-name{
    font-family: "Poppins-Medium", sans-serif;
    font-style: italic;
    font-weight: bolder;
  }
}

.Generic-Sidebar-Slot,.Cookies-Sidebar-Slot {
  flex: none;
}

@media (min-width: 768px) {
  .Generic-LeftContent-Slot,.Cookies-LeftContent-Slot {
    width: 66%;
    padding: 30px;
  }

  .Generic-Sidebar-Slot,.Cookies-Sidebar-Slot {
    width: 34%;
  }
}
