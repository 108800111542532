.schedule-to-ship-body {
  background-color: white;
  margin-bottom: 35px;
  p {
    margin-bottom: 10px;
  }
  strong {
    font-weight: 700;
    color: #2a343f;
    font-family: "NotoSansBold";
  }

  .stsb-head {
    padding: 25px 0;
    .proceed-Btn {
      margin-top: 0;
    }
    .notify {
      font-size: 12px;
      display: flex;
      margin-top: 15px;
      img {
        margin-right: 5px;
      }
    }
  }

  .stsb-body {
    padding: 25px 0;
    border-top: 2px solid #edf6fb;
    border-bottom: 2px solid #edf6fb;
    border-right: 2px solid #edf6fb;
    .stsb-body-title {
      margin-bottom: 20px;
      img {
        width: 25px;
        height: auto;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
      }
    }
    .stsb-body-content {
      text-align: center;
      .wrapper {
        display: grid;
        grid-template-columns: repeat(5, auto);
        background-color: #fff;
        color: #444;
        .t-row {
          display: contents;
          &:nth-child(even) .box {
            background-color: #f4f7f9;
          }
        }
      }
      .box {
        border-top-right-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(23, 114, 183, 0.2);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
      }
      form {
        display: inherit;
      }
      input {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        box-shadow: inset 0 1px 5px 0 #d4d9de;
        border: solid 1px #d4d9de;
        background-color: #fff;
        text-align: center;
        font-size: 11px;
      }
      input[type="number"]:disabled {
        background-color: #d4d9de;
      }

      .prod-info {
        span {
          margin-top: 5px;
          color: #337ab7;
          cursor: pointer;
        }
        img {
          max-width: 150px;
        }
      }
      .hide {
        opacity: 0 !important;
      }
    }
    .week-null {
      input {
        color: transparent;
      }
    }
    .text-notice {
      color: red;
      display: block;
    }
  }
  td {
    border: 1px solid black;
  }
}
