.cookie-policy-copy {
  h4 {
    font-family: Poppins-medium, sans-serif;
    font-size: 1.87em;
    font-weight: 500;
    color: #2a343f;
    padding-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
}
#cookie-list .ot-sdk-cookie-policy {
  table {
    margin-top: 10px;
    overflow-x: auto;
  }
  h4 {
    font-family: Poppins-medium, sans-serif;
    font-size: 1.67em;
    font-weight: 500;
    color: #2a343f;
    padding-bottom: 10px;
  }
  th,
  tr,
  td {
    font-family: NotoSans, sans-serif;
    font-size: 14px;
    padding: 0.75rem;
    letter-spacing: 0.5px;
  }

  thead {
    background-color: #549cc9;
    th {
      color: #fff;
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 530px) {
    tr:nth-child(odd) a {
      overflow: auto;
    }
    .ot-host-td::before {
      content: "Cookie Subgroup";
    }
    .ot-cookies-td::before {
      content: "Cookies";
    }
    .ot-cookies-type::before {
      content: "Cookies used";
    }
    .ot-life-span-td::before {
      content: "Lifespan";
    }
  }
}
