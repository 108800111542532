cx-footer-navigation,
cx-footer-navigation cx-navigation-ui {
  background: none;
}

cx-footer-navigation cx-navigation-ui > nav > ul > li {
  outline: none;
  margin-bottom: 23px;
  margin-top: 0;
  margin-left: 250px;
  margin-right: auto;

  padding: 0px 20px 0px 27px;
  border-left: 2px solid #13181d;
  height: 245px;

  & .childs > li {
    margin-bottom: 23px;

    & a {
      font-family: NotoSans, sans-serif;
      font-size: 14px;
    }
  }
}

footer cx-page-layout.footer cx-page-slot cx-paragraph,
footer cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) {
  color: #b1bdc4;
  font-size: 11px;
}

cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
  margin: 0;
}

@media screen and (max-width: 767px) {

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding-left: 5%;
  }

  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: 30px 0 30px 10%;
  }
  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -80px 0 30px 25%;
  }
  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -80px 0 30px 40%;
  }
  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -80px 0 30px 55%;
  }
  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -85px 0 30px 70%;
  }
  footer cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) {
    padding-bottom: 0;

    cx-generic-link {
      padding-bottom: 0;

      a {
        min-height: 0;
      }
    }
  }

  cx-footer-navigation cx-navigation-ui > nav {
    height: auto;

    & > ul > li {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      border-left: none;
      padding-left: 0;
      padding-right: 0;
      height: auto;

      & > span {
        display: none;
      }

      & .childs > li {

        & a {
          min-height: 0;
        }
      }
    }
  }

  footer cx-page-layout.footer cx-page-slot cx-paragraph,
  footer cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) {
    margin: 0;
    padding: 0;

    p {
      margin: 0;
      padding: 20px;
    }
  }
}


