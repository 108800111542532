.LandingPage2Template .Section2B {
  margin-bottom: 30px;

  cx-banner {
    min-height: 440px;
  }
}

cx-breadcrumb h1 {
  text-transform: none;
}

.btn {
  border-radius: 0.25rem;
}

.login-details {
  .forgot-text a,
  .new-customer a {
    display: inline;
  }
}

.account-order-filter-btn {
  button.btn {
    padding: 11px 12px;
    white-space: normal;
  }
}

.alert.alert-success {
  display: none;
}

.d-block.modal.fade.show {
  background-color: transparent;

  @media only screen and (min-width: 992px) {
    .modal-dialog {
      max-width: fit-content;
    }
  }
}
