.LoginPageTemplate {
  cx-page-slot.RegistrationSlot.has-components {
    max-width: 1380px;
    padding-top: 0;
  }
  cx-page-slot.CustomerRegistrationSlot.has-components {
    max-width: 1380px;
    padding-top: 0;
  }
}

app-account-registration {
  min-width: 100% !important;
}
app-user-confirmation {
  min-width: 100% !important;
}

app-account-confirmation-information {
  min-width: 100% !important;
}

app-user-registration {
  min-width: 100% !important;
}
#registerBilling .popover-body {
  font-size: 11px;
  font-family: "NotoSansBold", sans-serif;
  color: #fff;
  padding: 10px !important;
}
#registerBilling .bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
  border-top-color: #5b646e !important;
  bottom: 4px;
}
#registerBilling .popover {
  text-align: center !important;
  background-color: #5b646e !important;
  max-width: 244px !important;
}

app-statement-display {
  width: 867px !important;
}

.statement-dialog .modal-dialog.modal-lg {
  margin: 5% 16% !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 993px) and (min-width: 1200px) {
  .statement-dialog .modal-dialog.modal-lg {
    margin: 5% 10% !important;
    max-width: 100% !important;
  }

  .statement-dialog .modal-dialog.modal-lg .modal-content {
    min-width: 95% !important;
    width: fit-content;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .statement-dialog .modal-dialog.modal-lg {
    margin: 5% 20% !important;
    max-width: 100% !important;
  }
  .statement-dialog .modal-dialog.modal-lg .modal-content {
    min-width: 90% !important;
    width: inherit;
    height: inherit;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  app-statement-display {
    width: 100%;
  }

  .statement-dialog .modal-dialog.modal-lg .modal-content {
    min-width: 70% !important;
    width: fit-content;
  }
}

.statement-dialog {
  width: 100% !important;
}

.role-type {
  &-container {
    position: relative;
    max-width: 389px;
  }
  height: 44px;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: transparent;
  display: block !important;
  padding: 8px 12px;
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
  z-index: 10;
  position: relative;
  &-arrow {
    right: 10px;
    top: 15px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #5b646e;
  }
  &.ng-touched {
    border-color: #db0002;
  }
  &.ng-dirty {
    border-color: #d4d9de;
  }
}
