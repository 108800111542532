.theme-green .bs-datepicker-head {
  background-color: #ffff !important;
  height: 38px !important;
}
.bs-datepicker-head {
  padding: 0 !important;
}
.bs-datepicker-head button {
  color: #0057a6 !important;
}
.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  font-size: 48px !important;
  margin-top: -11px !important;
}
.bs-datepicker-body {
  border: none !important;
}
.theme-green .bs-datepicker-body table td.week span {
  display: none !important;
}
.bs-datepicker-head button.current {
  font-size: 12px !important;
  font-family: "NotoSansBold", sans-serif !important;
  color: #2a343f !important;
  padding: 0 !important;
  margin: 0 -38px !important;
  min-width: 37px !important;
  padding-top: 3px !important;
  text-transform: capitalize;
}
.weeks thead:before,
.weeks thead:after {
  width: 94%;
  content: " ";
  border-top: 1px solid #e5e9ec;
  position: absolute;
  left: 3%;
}
.bs-datepicker-body table th {
  color: #5b646e !important;
  font-size: 11px !important;
  text-transform: capitalize;
}
.bs-datepicker-container {
  padding: 0px !important;
}
.bs-datepicker-body {
  padding: 0 !important;
  min-width: 270px !important;
}
.weeks thead {
  height: 33px !important;
}
bs-datepicker-container {
  top: 3px !important;
}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #0055a8 !important;
  border-radius: 4px !important;
}
.bs-datepicker-body table.days span:hover {
  background-color: #0055a8 !important;
  border-radius: 4px !important;
  color: #ffffff;
}
.bs-datepicker-body table td span.disabled:hover,
.bs-datepicker-body table td.disabled span:hover {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.order-list-date .bs-datepicker-body table td.week span {
  display: none;
}

.order-list-date .bs-datepicker-head {
  padding: 8px !important;
}
.order-list-date {
  left: 40px;
}
.order-list-date .bs-datepicker-body table td span.selected,
.order-list-date .bs-datepicker-body table td.selected span,
.order-list-date .bs-datepicker-body table td span[class*="select-"]:after,
.order-list-date .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #0055a8 !important;
  border-radius: 4px !important;
}
.deliveryPickup .bs-datepicker-body table td span.disabled,
.deliveryPickup .bs-datepicker-body table td.disabled span {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.deliveryPickup .bs-datepicker-body table td span,
.deliveryPickup .bs-datepicker-body table td span {
  background-color: #e5e9ec !important;
  border-radius: 4px !important;
}
.deliveryPickup.bs-datepicker-body table td span.is-other-month,
.deliveryPickup .bs-datepicker-body table td span.is-other-month:hover {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.order-list-date .bs-datepicker-body table td span.disabled,
.order-list-date .bs-datepicker-body table td.disabled span {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
  margin-top: 5px !important
}
.order-list-date .bs-datepicker-body table td span,
.order-list-date .bs-datepicker-body table td span {
  background-color: #e5e9ec !important;
  border-radius: 4px !important;
  margin-top: 5px !important
}
.order-list-date .bs-datepicker-body table td span.is-other-month,
.order-list-date .bs-datepicker-body table td span.is-other-month:hover {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important;
  margin-top: 5px !important
}
@media only screen and (min-width: 375px) and (max-width: 767px) and (orientation: portrait){
.deliveryPickup{
  margin: 0 0 0 100px;
}}
.deliveryPickup .bs-datepicker-body table td .is-other-month{
  background-color: #fff !important;
}
