.total-Price-Section {
  padding: 0 30px 0 15px;
  p {
    margin-bottom: 5px;
  }
}
.shipTo-Header,
.your-Price,
.est-Delivery-Date {
  font-size: 12px !important;
  color: #5b646e;
  font-family: "NotoSansBold", sans-serif;
}
.total-Cost {
  background-color: #f4f7f9;
  padding: 5px 0;
  margin-top: 3px;
}
.total-Amount-Span {
  font-size: 20px;
  color: black;
  font-family: "NotoSansBold", sans-serif;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .total-Price-Section {
    padding: 1px 30px 0 25px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 600px) {
  .total-Price-Section {
    padding: 0 30px 0 15px;
    margin: 10px 0 0 0;
  }
  .sub-Total-Section {
    padding: 13px 7px 6px 14px;
  }
}

@media only screen and (min-device-width: 768px) {
  .sub-Total-Section {
    p {
      margin-bottom: 5px;
    }
  }
}
