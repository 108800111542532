@media only screen and (max-width: 768px) {
  cx-page-layout.LandingPage2Template {
    padding-bottom: 310px;
  }

  .TopContent a {
    min-height: 0;
  }

  #continueShoppingId {
    margin: 0;
    min-height: 0;
  }

  .save-document {
    display: flex;
    justify-content: flex-end;

    > a {
      min-height: 0;
    }
  }

  app-statement-display {
    width: 100%!important;
    .statement-dialog {
      width: 100vw!important;
      .modal-header {
        width: auto;
      }
    }
  }
}

.reservation-Container {
  .btn.cancel-Btn,
  .btn.save-Btun {
    min-height: 0;
  }
}

@media only screen and (min-width: 768px) {
  section.standart-cart section#leftCont-rightNav-cart[_ngcontent-serverApp-c157] > section.left-section[_ngcontent-serverApp-c157] {
    width: auto;
  }

  section.facets-filter {
    #cx-active-facets-groupName {
      flex: 0;
      font-size: 12px;
      min-width: fit-content;
      margin: 7px 10px 0 0;
      color: #5b646e;
    }

    cx-active-facets a > span {
      font-size: 12px;
    }
  }

  .noReserve-Middle-Section-Content{
    button.btn-Reserve{
      font-size: 18px;
      font-weight: normal;
      padding: 10px;
    }
  }

  .active-season-Section > section > section.row:nth-child(1) {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: auto;

    &:before,
    &:after {
      content: none;
    }

    .col-lg-9 {
      max-width: none;
      flex-basis: auto;
      flex-shrink: 0;
      width: auto;
    }

    .col-lg-3 {
      max-width: none;
      flex-basis: auto;
      width: auto;

      btn{
        min-height: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .facets cx-facet div a {
    min-height: 0;
  }

  .ad-files-text {
    display: flex;
    padding-right: 20px;
    justify-content: end;

    a.download {
      min-height: 0;
    }
  }

  .xls-pdf-invoice {
    div a {
      min-height: 0;
      display: inline;
    }
  }

  .credits-table > tr > td > a {
    min-height: 0;
  }
  .terms-Conditions p a,
  .place-flu-order a {
    min-height: 0;
    display: inline;
  }

  .preheader {
    > section.float-right {
      float: none !important;
      display: block;

      .middle-row {
        float: none !important;
        display: block;
      }

      .header-links {
        width: auto;
        float: right !important;
      }
    }
  }

  .BodyContent app-order-details .details-header .middle-row a {
    display: inline;
  }

  .BottomHeaderSlot cx-breadcrumb a {
    min-width: 0;
  }

  .ad-product-details .quick-prod-details .vol-Pricing {
    ngb-popover-window.popover {
      left: 20px !important;
      width: fit-content;
      max-width: none;
    }
  }

  .vol-Pricing ngb-popover-window.popover {
    left: 0!important;

    div.popover-body .tb tr{
        display: flex;
        flex-wrap: wrap;
        width: auto;
        height: auto;
        min-height: 30px;
    }
  }

  #vol-price-pdp {
    width: auto;

    #tb-vol-price-pdp  {
      tr {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        height: auto;
        min-height: 30px;
        justify-content: space-between;

        td {
          width: auto;

          &:empty {
            display: none;
          }
        }
      }
    }
  }


  .influenza-Contact-Section .container .res-contact ul li a.prefer-Value {
    display: inline;
  }
}

#productList-area .credits-section {
  .openstatement-btn button.btn {
    font-weight: normal;
  }
}


