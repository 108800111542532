.faq-container {
  display: flex;
  align-items: stretch;
  background-color: #fff;
}
.child-container-one {
  flex-grow: 6;
}
.child-container-two {
  flex-grow: 4;
}

cx-page-layout.ContentPage1Template {
  margin: 0px 0px 40px 0px;
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  padding: 0px;
}

cx-page-layout cx-page-slot.FAQ-left {
  min-height: 1px;
  width: 66%;
  flex: auto;
}

cx-page-layout cx-page-slot.ContentSlotFAQ {
  flex: auto;
  background: white;
  padding-left: 20px;
  max-width: 32%;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    width: 100%;
    min-width: 100%;
  }
  cx-page-layout cx-page-slot.ContentSlotFAQ {
    min-width: 100%;
    width: 100%;
  }
  cx-page-layout.ContentPage1Template {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    width: 66%;
    min-width: 66%;
  }
  cx-page-layout cx-page-slot.ContentSlotFAQ {
    min-width: 32%;
  }
  cx-page-layout.ContentPage1Template {
    margin: 0px 0px 40px 0px;
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}

.order-info p {
  padding: 20px 0px 0px 0px;
}

app-faq-title .left-section {
  background-color: #ffffff;
  padding: 30px 20px 0;
  border-right: 4px solid #edf6fb;
}
.faq p {
  margin-bottom: 0;
  font-size: 14px;
}

.faq-panel-header {
  margin-top: 35px;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 0;
  h3 {
    margin-bottom: 0;
    color: #5b646e;
    font-size: 20px;
  }
}
.child-container-one {
  border-right: 4px solid #edf6fb;
  padding: 0px 45px 30px 45px;
  width: 42%;
}

.all-child-btn,
.item-child-container,
.item-child-answer {
  display: none;
}
.show {
  display: block !important;
}
.all-child-btn {
  margin-bottom: 10px;
  padding: 0;
  &.show {
    display: inline-block !important;
  }
}
.toggle-item {
  text-align: right;
}
.faq-item {
  button {
    border: none;
    background-color: transparent;
  }
  h5 {
    font-size: 12px;
    padding-right: 15px;
    position: relative;
    border-bottom: none;
    color: #5b646e;
    font-family: "NotoSans", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.item-child {
  border-top: 1px solid #bfc4c8;
}
.item-child-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.item-child-answer {
  border-top: 1px solid #bfc4c8;
  padding: 10px 0 15px;
}
.all-child-btn {
  font-size: 14px;
  color: #337ab7;
}

.faq-item button.item-btn,
.toggle-item button.child-btn {
  background-image: url(/../../assets/icons/faq-plus.png);
  width: 16px;
  height: 16px;
  background-size: 16px;
  display: block;
  cursor: pointer;
  &.active {
    background-image: url(/../../assets/icons/faq-minus.png);
  }
}

.item-child-question {
  h5 {
    width: 90%;
  }
  .toggle-item {
    text-align: right;
  }
}
.child-container-one {
  padding: 0px 20px 30px 20px;
}

@media screen and (min-width: 768px) {
  app-faq-title .left-section {
    padding: 30px 30px 0;
  }
  .child-container-one {
    padding: 0px 30px 30px;
  }
}
