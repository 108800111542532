cx-page-layout.footer
  cx-page-slot
  > cx-banner:nth-child(1)
  > cx-generic-link
  cx-media
  img {
  background: transparent;
  width: 333px;
  margin: 101px 0px 28px 50px;
}

cx-footer-navigation cx-navigation-ui {
  text-transform: capitalize;
  font-weight: 700;
  font-family: NotoSansBold, sans-serif;
  font-size: 14px;
  color: #73aed3;
  margin-bottom: 20px;
  padding: 0 !important;
}

cx-footer-navigation cx-navigation-ui {
  justify-content: center;
  margin: -140px 0px 20px 125px;
}

cx-page-layout.footer {
  background: #2a343f;
}

cx-media {
  display: inline-block;
}

cx-footer-navigation cx-navigation-ui h5 {
  text-transform: capitalize;
  font-weight: bold;
  font-family: "NotoSansBold", sans-serif;
  font-size: 14px;
  color: #73aed3;
  margin-bottom: 20px;
  padding: 0px !important;
}

div.childs > nav > cx-generic-link > a {
  font-family: "NotoSans", sans-serif;
  font-size: 14px;
}

cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
  padding: 0px 0px 42px 29%;
  display: inline-block;
  color: #b1bdc4;
  font-size: 20px;
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
  margin: -80px 0px 0px 40%;
  display: inline-block;

  cx-generic-link {
    padding: 0px 0px 0px 0px;
    display: inline-block;
  }

  cx-media img {
    height: 45px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
  margin: -80px 0px 0px 45%;
  display: inline-block;

  cx-generic-link {
    padding: 0px 0px 0px 0px;
    display: inline-block;
  }

  cx-media img {
    height: 45px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
  margin: -80px 0px 0px 50%;
  display: inline-block;

  cx-generic-link {
    padding: 0px 0px 0px 0px;
    display: inline-block;
  }

  cx-media img {
    height: 45px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
  margin: -80px 0px 0px 55%;
  display: inline-block;

  cx-generic-link {
    padding: 0px 0px 0px 0px;
    display: inline-block;
  }

  cx-media img {
    height: 45px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
  margin: -84px 0px 0px 60%;

  cx-generic-link {
    padding: 0px 0px 0px 0px;
    display: inline-block;
  }

  cx-media img {
    height: 56px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) {
  border-top: 1px solid #13181d;
  display: inline-block;
  padding: 20px;

  cx-generic-link {
    padding: 0px 0px 0px 197px;
  }

  cx-media img {
    max-width: 85px;
    background: none;
  }
}

cx-page-layout.footer cx-page-slot cx-paragraph {
  margin: -46px 0px 0px 330px;
  color: #b1bdc4;
  font-size: 11px;
  padding: 0px 0px 20px 0px;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media
    img {
    background: transparent;
    width: 203px !important;
    margin: 101px 0px 28px 10px;
  }
  cx-footer-navigation cx-navigation-ui > nav {
    margin: 5px 0px 0px 30px;
    padding: 0px 20px 0px 30px;
  }
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding: 0px 0px 42px 29%;
    display: inline-block;
    color: #b1bdc4;
    font-size: 20px;
  }
  cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: -80px 0px 0px 40%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -80px 0px 0px 45%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -80px 0px 0px 50%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -80px 0px 0px 55%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -90px 0px 0px 60%;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 56px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-generic-link {
    padding: 0px 0px 0px 0px !important;
  }
  cx-footer-navigation cx-navigation-ui {
    justify-content: center;
    margin: -140px 0px 49px 206px !important;
  }
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) {
    //margin: -46px 0px 0px 202px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-footer-navigation cx-navigation-ui {
    justify-content: flex-start;
    margin: -114px 0 29px 269px;
  }
  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media {
    width: 100%;
  }
  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media
    img {
    background: transparent;
    width: 222px;
    margin: 101px 0px 28px 17px;
  }

  cx-footer-navigation cx-navigation-ui > nav {
    padding: 0px 20px 0px 23px;
    height: 192px;
    margin: 0;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding: 0px 0px 42px 10%;
    display: inline-block;
    color: #b1bdc4;
    font-size: 20px;
  }
  cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: -80px 0px 0px 35%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -80px 0px 0px 45%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;

      cx-media img {
        height: 45px;
        background: none;
      }
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -80px 0px 0px 55%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -80px 0px 0px 65%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -84px 0px 0px 75%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 56px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-generic-link {
    padding: 0px 0px 0px 0px;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) p {
    //padding: 0px 0px 20px 0px;
    //margin: 27px 0px 25px -182px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media
    img {
    background: transparent;
    width: 279px;
    margin: 101px 0px 28px 20px;
  }
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding: 0px 0px 42px 15%;
    display: inline-block;
    color: #b1bdc4;
    font-size: 20px;
  }
  cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: -80px 0px 0px 35%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -80px 0px 0px 45%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -80px 0px 0px 55%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -80px 0px 0px 65%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -84px 0px 0px 75%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 56px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-generic-link {
    padding: 0px 0px 0px 113px;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) {
    //margin: -46px 0px 0px 311px;
    //color: #b1bdc4;
    //font-size: 11px;
    //padding: 0px 0px 20px 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) {
    //margin: -30px 300px 10px 330px;
    //color: #b1bdc4;
    //font-size: 11px;
    //padding: 0px 0px 20px 0px;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) p {
    //margin: 0px 0px 25px -105px;
    //padding: 0px 0px 20px 0px;
  }

  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media
    img {
    background: transparent;
    width: 215px;
    margin: 101px 0px 28px 17px;
    max-width: 215px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  cx-footer-navigation cx-navigation-ui {
    justify-content: flex-start;
    margin: 20px 0px 20px -118px;
  }

  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media {
    border-bottom: 1px solid black;

    img {
      background: transparent;
      width: 65%;
      padding: 20px;
      margin: 0;

      max-width: 367px;
    }
  }

  cx-footer-navigation cx-navigation-ui > nav {
    margin: 5px 0px 0px 110px;
    padding: 0px 20px 0px 48px;
    height: 192px;
    border-left: none;
  }
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding: 0px 0px 0px 10%;
    display: inline-block;
    color: #b1bdc4;
    font-size: 20px;
  }
  cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: 30px 0px 30px 10%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -75px 0px 30px 25%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -75px 0px 30px 40%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -75px 0px 30px 55%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -80px 0px 30px 70%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 56px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-generic-link {
    padding: 0px 0px 10px 0px;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) p {
    margin: 35px 25px 25px -155px;
    padding: 0px 0px 20px 0px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  cx-footer-navigation cx-navigation-ui {
    justify-content: flex-start;
    margin: 0px 0px 49px 20px !important;
  }

  cx-page-layout.footer
    cx-page-slot
    > cx-banner:nth-child(1)
    > cx-generic-link
    cx-media
    img {
    background: transparent;
    width: 316px;
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid black;
  }

  cx-footer-navigation cx-navigation-ui > nav {
    margin: 5px 0px 0px 20px;
    padding: 0px 20px 0px 48px;
    height: 192px;
    border-left: none;
  }
  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(3) {
    padding: 0px 0px 0px 10%;
    display: inline-block;
    color: #b1bdc4;
    font-size: 20px;
  }
  cx-page-layout.footer cx-page-slot cx-banner:nth-child(4) {
    margin: 30px 0px 30px 10%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(5) {
    margin: -75px 0px 30px 25%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(6) {
    margin: -75px 0px 30px 40%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(7) {
    margin: -75px 0px 30px 55%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      height: 45px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(8) {
    margin: -84px 0px 30px 70%;
    display: inline-block;

    cx-generic-link {
      padding: 0px 0px 0px 0px;
      display: inline-block;
    }

    cx-media img {
      max-width: 85px;
      background: none;
    }
  }

  cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-generic-link {
    padding: 0px 0px 0px 0px;
  }

  cx-page-layout.footer cx-page-slot cx-paragraph:nth-child(10) p {
    margin: 35px 25px 25px -92px;
    padding: 0px 0px 20px 0px;
  }
}

cx-page-layout.footer cx-page-slot cx-banner:nth-child(9) cx-media img {
  margin-top: -15px;
}

cx-storefront > footer cx-paragraph p {
  margin-top: 8px;
}
