// To control visual effects while searching the searchbox requires
// some CSS rules to the outer elements.
%cx-searchbox__body {
    &.searchbox-is-active {
        &.has-searchbox-results {
            cx-searchbox {
                .results {
                    display: block;
                    border-radius: 4px;
                    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
                    border: 1px solid #d4d9de;
                }
            }
            // the searchbox requires an overlay over the page (except for the header)
            // we do this with an overlay under the header, on top of the rest of the page
            header {
                position: relative;
                z-index: 100;
            }
            cx-storefront {
                &:before {
                    content: '';
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                }
            }
        }
    }
    &:not(.searchbox-is-active) {
        @include media-breakpoint-down(sm) {
            // hide the input on mobile when there's no interaction with searchbox
            cx-searchbox {
                input {
                    // we cannot use display:none, visible:hidden or opacity: 0
                    // as this will no longer emit a focus event to the controller logic
                    width: 0;
                    padding: 0;
                }
                button.reset {
                    display: none;
                }
            }
        }
    }
}

%cx-searchbox {
    @include media-breakpoint-up(md) {
        // we position the parent relative to ensure the result panel
        // is aligned to the left of searchbox
        width: fit-content !important;
        position: relative;
        z-index: 0;
    }
    z-index: 10;
    display: block;
    >* {
        z-index: 20;
        @include media-breakpoint-up(md) {
            background-color: var(--cx-color-secondary);
            position: relative;
        }
    }
    a,
    .message {
        padding: 6px 16px;
        color: currentColor;
        user-select: none;
    }
    label {
        display: flex;
        align-content: stretch;
        margin: 0;
        padding-top: 6px;
        padding-inline-end: 6px;
        padding-bottom: 6px;
        padding-inline-start: 10px;
        @include media-breakpoint-up(md) {
            width: 27vw;
            min-width: 300px;
            max-width: 550px;
        }
        input {
            background: none;
            border: none;
            outline: none;
            display: block;
            @include media-breakpoint-down(sm) {
                position: unset !important;
                left: 0;
                top: 57px;
                width: 100%;
                background-color: #ffffff;
                padding: 6px 16px;
                height: 32px;
            }
            flex-basis: 100%;
            height: 35px;
            color: currentColor;
            z-index: 20;
            @include placeholder {
                color: currentColor;
            }
        }
        // hide reset icon when the input is empty
        &:not(.dirty) button.reset {
            display: none;
        }
        button,
        div.search-icon {
            color: currentColor;
            flex-basis: 48px;
            text-align: center;
            background: none;
            border: none;
            padding: 3px;
            &.reset cx-icon {
                &:before {
                    font-size: 1.4rem;
                }
                @include media-breakpoint-down(sm) {
                    position: relative;
                    left: 74px;
                    z-index: 20;
                    top: 52px;
                    margin-top: 0;
                }
            }
        }
    }
    .results {
        // hide the result by default
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        color: #000;
        background-color: #fff;
        font-size: var(--cx-font-small, 1rem);
        @include media-breakpoint-down(sm) {
            position: unset;
            z-index: 10;
        }
        a {
            text-decoration: none;
            cursor: pointer;
            font-weight: 800;
            .highlight,
            .search-results-highlight {
                font-weight: normal;
                font-style: normal;
            }
            &:hover,
            &:focus {
                background: var(--cx-color-light);
                color: currentColor;
            }
        }
        .suggestions {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding-inline-start: 0;
            margin-bottom: 0;
            li,
            a {
                flex: 100%;
                line-height: 2rem;
                display: block;
            }
        }
        .searchHistory {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding-inline-start: 0;
            margin-bottom: 0;
            li,
            a {
                flex: 100%;
                line-height: 2rem;
                display: block;
            }
        }
        .products {
            list-style: none;
            padding-inline-start: 0;
            margin-bottom: 0;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            a {
                display: grid;
                grid-column-gap: 16px;
                &.has-media {
                    // create a grid layout in case we show an image
                    grid-template-columns: 50px 1fr;
                }
                border-top: 1px solid white !important;
                cx-media {
                    grid-row: 1 / 3;
                }
                div.name {
                    text-overflow: ellipsis;
                    font-size: smaller !important;
                    font-weight: inherit;
                    color: rgb(0, 87, 166) !important; // Required for text-overflow to do anything
                    white-space: nowrap;
                    overflow: hidden;
                }
                .price {
                    font-weight: normal;
                }
            }
        }
    }
}

.searchboxSection{
    %cx-searchbox {
        label {
            max-width: 375px;
            min-width: 375px;
            width: 375px;
            @include media-breakpoint-up(md) {
                min-width: 300px;
                max-width: 550px;
            }   
        }
    }
}

.drop-down-header {
    color: #0057a6 !important;
    font-size: 14px;
    font-family: "NotoSansBold", sans-serif;
    border-bottom: 1px solid #d3d6db;
    height: auto;
    padding: 15px 15px 10px 15px !important;
    margin: 5px 0px 5px 0px !important;
    z-index: 999 !important;
}

.drop-down-title {
    color: #000 !important;
    font-weight: bold !important;
}

.searchHistory {
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 0.5px #d4d9de !important;
    border: 1px solid #d4d9de;
}

.itemHistory {
    color: rgb(0, 87, 166) !important;
    a {
        padding-left: 0px !important;
        font-size: 14px !important;
    }
}

.resetListStyle {
    list-style: none;
    padding: 0px 15px 0px 15px !important;
    margin-bottom: 0px !important;
    li {
        padding: 8px 8px 8px 0px !important
    }
}

.history-font-size {
    font-size: 14px;
    font-weight: bold !important;
    font-family: "NotoSansBold", sans-serif !important;
}

.searchbox {
    background-color: #ffffff;
}

.search-icon {
    border: 0 !important;
    font-size: 16px !important;
    color: #0057a6 !important;
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

cx-searchbox label input {
    color: #999999 !important;
    font-size: 14px;
}
cx-searchbox label .custom-searchbox-text {
  font-size: 12px;
}

cx-searchbox .results .products .drop-down-header a {
    font-size: 12px !important;
    padding: 4px !important;
    font-family: 'NOTOSANS', sans-serif;
    font-weight: normal;
    color: #0057a6 !important;
    cursor: pointer;
}

cx-searchbox .results .products .drop-down-header a:hover {
    background: none;
    text-decoration: underline;
}

.all-results-link {
    font-size: 12px !important;
    padding: 4px !important;
    font-family: 'NOTOSANS', sans-serif;
    font-weight: normal;
    color: #0057a6 !important;
    cursor: pointer;
}

a .all-results-link :hover {
    background: none;
    text-decoration: underline;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
    .header cx-searchbox cx-media img {
        width: 54px !important;
        max-width: 54px !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape){
    app-quick-order cx-searchbox label {
        width: 27vw;
        min-width: 222px;
    }
    .header cx-searchbox cx-media img {
        width: 54px !important;
        max-width: 54px !important;
    }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
    .header cx-searchbox cx-media img {
        width: 54px !important;
        max-width: 54px !important;
    }
    app-quick-order cx-searchbox label {
        width: 27vw;
        min-width: 233px;
    }
    app-saved-orders app-quick-order cx-searchbox label {
    min-width: 769px;
    max-width: 770px;
    }
}

@media screen and (min-width: 768) and (max-width: 1024px) and (orientation: portrait) {
.header cx-searchbox cx-media img {
    width: 54px !important;
    max-width: 54px !important;
}


    app-quick-order cx-searchbox label {
        width: 27vw;
        min-width: 223px;
    }
    .header cx-searchbox cx-media img {
        width: 54px !important;
    }
}

.push-right {
    float: right !important;
}

@media screen and (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
app-saved-orders app-quick-order cx-searchbox label {
    min-width: 613px;
    max-width: 613px;
}
}
