@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
 

.SearchResultsGridPageTemplate .SearchResultsGridSlot .product-page .main .content-right {
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 275px 275px 275px !important;
    grid-gap: 3px;
    width:fit-content;
}

.ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
  flex: initial;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 281px 281px 281px !important;
  grid-gap: 3px;
  width:fit-content;
}

.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
  width: 77.5% !important;
}

.SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
  width: 110%;
  margin: 0px 0px 0px -83px !important;
}

    .SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
      width: 110.4% !important;
      margin: 0px 0px 0px -68px;
  }

  cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
    max-width: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;
}
}

cx-page-layout.SearchResultsGridPageTemplate .featureProdTxt{
  background-color: #ffffff;
  min-height: 282px;
  width: 100%;
  margin: 0px 0px 0px 3px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .thumbnail-img{
    width: 111% !important;
}

  cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
    display: flex;
    flex-flow: column;
    height: 282px ;
    width: 41% !important;
  }
  .SearchResultsGridPageTemplate {
    width:100%;
  }
  cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
    max-width: 30% !important;
    width:100% !important;
  }
  cx-page-layout.SearchResultsGridPageTemplate .SearchResultsGridSlot{
    width: 50% !important;
  }
  cx-storefront main .SearchResultsGridPageTemplate {
    display: flex;
}
.SearchResultsGridPageTemplate .SearchResultsGridSlot .product-page .main .content-right {
  flex: initial;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat (auto-fit, minmax(280px, 1fr)) !important;
  grid-gap: 3px;
  width:fit-content;
}
.ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
  flex: initial;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat (auto-fit, minmax(280px, 1fr)) !important;
  grid-gap: 3px;
  width:fit-content;
}
.SearchResultsGridPageTemplate .featureProdTxt {
  background-color: #ffffff;
  min-height: 282px;
  margin: 0 0px !important;
  width:100%;
}

}

cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 76%;
  margin: 0px 0px 0px -14px;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait){
 
  .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 348px !important;
    grid-gap: 3px;
}
.SearchResultsGridPageTemplate .product-refinement .content {
  height: 108px;
  min-height: 0px !important;
}


 .SearchResultsGridPageTemplate .product-refinement #refineBtn{
  margin:0px !important;
 }
 cx-storefront main .SearchResultsGridPageTemplate {
  display: inline-block !important;
}
.SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
  width: 100% !important;
  margin: 0px 0px 0px 0px !important;
}
cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
  display: flex;
  flex-flow: column;
  width: 100% !important;
  padding:20px;
  background-color: #ffffff;
}
 cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
  max-width: 100% !important;
  margin: 0px !important;
  padding-bottom: 15px;
}
.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
  display: grid;
  flex-flow: column;
  height: 100%;
  width: 100%;
  margin: 0px 0px 0px 0px !important;
}
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape){
    cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
      width: 98% !important;
  }
  .thumbnail-img{
    width: 111% !important;
}
  }
  cx-storefront main .SearchResultsGridPageTemplate {
    display: flex;
}
  @media only screen and (min-width: 1280px) and (max-width: 1366px) {
    .SearchResultsGridPageTemplate  .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 348px 348px 348px !important;
      grid-gap: 3px;
    }

    .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 348px 348px 348px !important;
      grid-gap: 3px;
    }
    
  }

  @media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape){
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .product-refinement {
        width: 88% !important;
    }
    
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .product-refinement {
      display: flex;
      flex-flow: column;
      height: 100%;
      width: 82%;
      margin: 0px 0px 0px -82px;
    }

    
.SearchResultsGridPageTemplate .SearchResultsGridSlot .product-page .main .content-right {
  flex: initial;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 312px 312px !important;
  grid-gap: 3px;
  width:fit-content;
}

.ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
  flex: initial;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 315px 315px !important;
  grid-gap: 3px;
  width:fit-content;
}
   
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .productList{
      width: 104% !important;
      margin: 0px -27px !important;
    }
    
    }
    .SearchResultsGridPageTemplate .explorer {
      margin-bottom: 10px !important;
  }
    @media only screen and (min-width: 926px){
      .SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement .header{
        height: 108px !important;



        min-height: 108px !important;
      }
      .SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement .filterByTitle-main {
    margin-top: 0px !important;
    padding: 3px 0px 10px 0px !important;
    }
  }
 
    .SearchResultsGridPageTemplate .product-refinement .content
  {
      height: 108px;
      min-height: 108px;
  }
    
cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
    max-width: 26%;
    margin-left: 70px;
    margin-right: -24px;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;
}

.SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
  width: 112.4%;
  margin: 0px 0px 0px -118px;
}

.SearchResultsGridPageTemplate .product-page .main {
    flex: 2;
    display: flex;
    flex-flow: row;
    margin: 4px 0 0 4px;
}

.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
    width: 70%;
    margin: 0px 0px 0px -60px;
}

.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 71%;
    margin: 0px 0px 0px -63px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
      width: 103% !important;
      margin: 0px 0px 0px -26px !important;
  }
 
  .SearchResultsGridPageTemplate .ProductLeftRefinements .header {
    height: 77px !important;
    min-height: 77px !important;
  }
  .SearchResultsGridPageTemplate .ProductLeftRefinements .productList .product-page .featureProdTxt
   {
    background-color: #ffffff;
    min-height: 282px;
    margin: 0px -7px !important;
  }
  cx-storefront main .SearchResultsGridPageTemplate {
    display: flex !important;
  }
  .SearchResultsGridPageTemplate .SearchResultsGridSlot  .product-page .main .content-right{
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 285px 285px !important ;
    grid-gap: 3px;
    width: fit-content;
  }
  .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right{
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 286px 286px !important ;
    grid-gap: 4px;
    width: fit-content;
  }
    cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
    margin: 0px 34px 0px -19px !important;
    height: 100%;
    padding: 20px 0px 0px 0px;
    }
    
    cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
      max-width: 28% !important;
      margin-left: 5px;
      margin-right: 31px;
      padding-inline-end: 15px;
      padding-bottom: 15px;
      padding-inline-start: 15px;
  }
  }
  
 
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape){
    .ProductGridPageTemplate .ProductGridSlot .product-refinement{
      display: flex;
      flex-flow: column;
      height: 100%;
      width: 63% !important;
      font-size: 0.87em;
      margin: 0px 0px 0px -64px;
  }

  
  }

  cx-storefront main .SearchResultsGridPageTemplate {
    display: inherit;
}

@media screen and (min-width: 300px) and (max-width: 650px){
  .productList {
    padding: 20px 15px 0 10px;
}
}

@media screen and (min-width: 926px){
  .SearchResultsGridSlot .SearchResultsGridSlot .product-page .main {
    flex: 2;
    display: flex;
    flex-flow: row nowrap;
    margin: 4px 0 0 4px;
    width: 1041;
  }
  .SearchResultsGridSlot .SearchResultsGridSlot .productList {
    width: 112% !important;
    margin: 0px 72px 0px -112px;
}
cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
  max-width: 26%;
  margin-left: -1px;
  margin-right: 16px;
 
}

.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement .content {
  height: 100%;
  background-color: #fff;
  margin: 0px 4px 0px 0px;
}


cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
 
   margin: 0px 0px 0px -12px !important;
}
}

@media screen and (min-width:768px) and (max-width:1024px)and (orientation:portrait){
  .ProductGridPageTemplate .featureProdTxt{
      margin: 10px -7px;
      width:100%;
      min-height: 282px;
      background: white;
  }}

  @media screen and (min-width:375px) and (max-width:812px) and (orientation : portrait){
    .ProductGridPageTemplate .featureProdTxt{
        background-color: #ffffff;
        min-height: 282px;
        margin: 0 3px;
     }
    }

    .thumbnail-img{
      width: 100%;
  }

 .SearchResultsGridPageTemplate  .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 348px 348px 348px;
      grid-gap: 3px;
    }

    .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 348px 348px 348px;
      grid-gap: 3px;
    }
    
    @media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
      .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
        flex: initial;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 348px !important;
        grid-gap: 3px;
      }
    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
    .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 616px!important;
      grid-gap: 3px;
      width: fit-content;
      margin: 0px 0px 0px 7px;
    }
  }

  @media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation:portrait){
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .productList {
      width: 100% !important;
      margin: 0px !important;
  }
  cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement{
    width: 104% !important;
  }
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
  width: 25% !important;
  }

  .ProductGridPageTemplate .ProductLeftRefinements .product-refinement{
    width: 92% !important;
    min-width: 92% !important;
    background-color: #fff;

  }
  .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
    flex: initial;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 269px 269px 269px !important;
    }
    cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements {
      max-width: 22% !important;
      width: 22% !important;
      padding: 0px; 
      margin: 0px;
    }
    .SearchResultsGridPageTemplate .SearchResultsGridSlot .product-page .main .content-right {
      flex: initial;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 261px 261px 261px!important;
  }
  .product-page .header .content-right {
    min-height: 112px;
    margin: 0px 0px 0px 0px;
}
  }

  @media only screen and (min-width: 926px){
    .SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement .content{
      height: 100%;
      background-color: #fff;
      width: 99%;
      min-width: 99%;
  }
  .ProductGridPageTemplate .ProductLeftRefinements .product-refinement .content{
    height: 100%;
    background-color: #fff;
 
}
  }
 
  @media only screen and (min-width: 911px) {
    .SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement .content{
      height: 100%;
      background-color: #fff;
      width: 99%;
      min-width: 99%;
  }
  .ProductGridPageTemplate .ProductLeftRefinements .product-refinement .content{
    height: 100%;
    background-color: #fff;
    
}
.ProductGridPageTemplate .ProductGridSlot .productList {
    width: 100%;
    margin:0px;
  }
}

@media screen and (max-width:926px) and (orientation: portrait) {
  .ProductGridPageTemplate .ProductLeftRefinements .product-refinement .content-left{
    max-width: 179px !important;
    width: 179px !important;
  }
}

@media screen and (max-width:1024px) and (orientation: portrait) {
  .ProductGridPageTemplate .ProductLeftRefinements .product-refinement .content-left{
    max-width: 179px !important;
    width: 179px !important;
  }
}

@media screen and (min-width: 813px) and (orientation: portrait) {
  cx-page-layout.SearchResultsGridPageTemplate .ProductLeftRefinements .product-refinement {
  margin: 0px !important;
  padding: 0px !important; 
  width: 224px !important;
  }
 
  header .MiniCart {
    margin: 0px;
  }
}