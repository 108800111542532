.popover-links {
  border: 0px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  padding: 0 11px 15px;
  min-width: 270px;
  max-width: 276px;
  border-radius: 6px;
  height: auto;
  z-index: 1060;
  min-height: 114px;
}
.order-detail-save {
  max-width: 300px !important;
}
.order-detail-save .popover-header {
  padding: 20px 0px !important;
}
.delete-popover-container ngb-popover-window {
  left: 60px !important;
  border: 0px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  padding: 0px 0px 0px 9px;
  min-width: 238px;
  max-width: 276px;
  border-radius: 6px;
  top: -8px !important;
  height: auto;
  z-index: 1060;
  min-height: 114px;
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .delete-popover-container ngb-popover-window {
    left: 0px !important;
  }
  @media (max-width: 991.98px) {
    .carousel-modal cx-media img {
      max-width: -moz-fit-content;
      max-width: 100%;
    }
  }
  .popover-links {
    left: 17px !important;
  }
  .deleterwd-small {
    left: 0px !important;
  }
  app-statement-display .statement-dialog .modal-header {
    width: 217%;
  }
  app-statement-display .statement-dialog {
    width: 20% !important;
  }
}
.popover-links .arrow::before,
.bs-popover-top .arrow::before,
.delete-popover-container .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border: none;
}
.popover-links > .arrow:after,
.delete-popover-container ngb-popover-window > .arrow:after {
  content: "";
  border-width: 10px;
}

.popover-links > .arrow,
.delete-popover-container ngb-popover-window > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover-links > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff !important;
  border-bottom-width: 0;
}
.delete-popover-container ngb-popover-window > .arrow:after {
  bottom: 1px;
  margin-left: -43px;
  content: " ";
  border-top-color: #fff !important;
  border-bottom-width: 0;
}

.popover-links .popover-body .white-button,
.popover-links .popover-body .blue-button,
.delete-popover-container ngb-popover-window .popover-body .white-button,
.delete-popover-container ngb-popover-window .popover-body .blue-button {
  width: 45%;
  height: 32px;
  padding: 5px;
}
.popover-links .popover-body,
.delete-popover-container ngb-popover-window .popover-body {
  padding: 0;
}
.popover-links .popover-header,
.delete-popover-container ngb-popover-window .popover-header {
  font-family: "NotoSansBold", sans-serif;
  color: #5b646e;
  padding: 20px 10px;
  margin: 0;
  font-size: 12px;
  background-color: #ffffff;
  border-bottom: none;
  text-align: center;
}

.popover-links .popover-body .white-button,
.delete-popover-container ngb-popover-window .popover-body .white-button {
  margin: 0 !important;
}

.popover-links .popover-body .blue-button,
.delete-popover-container ngb-popover-window .popover-body .blue-button {
  margin: 0px 9px 0px 10px;
}

.cart-icons ngb-popover-window .popover-header .minicart-header {
  height: 89px;
  display: grid;
  background: #edf6fb;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins-Medium", sans-serif;
  color: #4191c3;
  text-align: center;
  box-shadow: 0 2px 6px rgb(23 114 183 / 20%);
  -webkit-box-shadow: 0 2px 6px rgb(23 114 183 / 20%);
  -moz-box-shadow: 0 2px 6px rgba(23, 114, 183, 0.2);
}

app-mini-cart .cart-icons > ngb-popover-window .popover-header {
  padding: 0px;
}
app-mini-cart .cart-icons > ngb-popover-window {
  width: 423px;
  min-width: 448px;
  padding: 0px;
  max-height: 800px;
  top: 10px !important;
  border: none;
  left: -7px !important;
  box-shadow: 0 2px 6px rgb(23 114 183 / 20%);
  -webkit-box-shadow: 0 2px 6px rgb(23 114 183 / 20%);
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .miniCart-listitem
  ul {
  color: #337ab7;
  list-style: none;
  font-weight: bold;
  position: relative;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  padding: 0;
  li {
    padding: 12px;
    font-size: 14px !important;
    font-family: "NotoSans", sans-serif !important;
  }
}

app-mini-cart .cart-icons > ngb-popover-window .mini-triangle {
  border-color: #edf6fb transparent;
  border-style: solid;
  border-width: 0px 15px 15px 15px;
  height: 0px;
  width: 0px;
  position: absolute;
  top: -15px;
  left: 217px;
}

app-mini-cart .cart-icons ngb-popover-window > .arrow,
ngb-popover-window > .arrow:after {
  display: none;
}

cx-page-layout cx-page-slot.MiniCart {
  min-height: 1px;
  display: block;
  text-align: right;
}
.popup-body {
  position: absolute;
  top: 0px;
  left: -40px;
  will-change: transform;
  transform: translate(743px, 447px);
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .unit-numbers {
  height: 44px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2a343f;
  border-bottom: 1px solid #e8e8e8;
  text-align: right;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .ul {
  margin: 0px 0px 0px 0px;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-prod-details {
  padding: 0px 0px 0px 0px;
  margin: 0px;
  li {
    padding: 10px 20px 0px 20px;
    text-align: left;
    .minicart-prod-doses {
      color: #5b646e;
      float: right;
      font-size: 14px;
    }
    .miniCart-price {
      color: #5b646e;
      margin: 0px !important;
      padding: 3px 0px 0px 55px;
    }
    a {
      color: #0057a6;
      padding: 0px 0px 0px 10px;
      .cart-prod-title {
        padding: 0px 0px 0px 12px;
      }
    }
    .img_loaded {
      width: 33px;
    }
  }
}

app-mini-cart .cart-icons > ngb-popover-window .popover-body .cart-prod-list {
  .blue-button {
    background: #0057a6;
    color: #ffffff;
    font-size: 14px;
    font-family: "NotoSansBold", sans-serif;
    opacity: 1;
    width: 91%;
    margin: 0px 20px 0px 20px;
    height: 44px;
    border: 0;
  }
  .white-button {
    background: #fff;
    font-size: 14px;
    font-family: "NotoSansBold", sans-serif;
    color: #0057a6;
    opacity: 1;
    width: 91%;
    margin: 9px 20px 9px 20px !important;
    height: 40px;
    border: 2px solid #0057a6;
    outline: 0;
  }
}

app-mini-cart .cart-icons > ngb-popover-window .popover-body .cart-prod-list {
  padding: 6px 0px 22px 0px;
}
ngb-popover-window.bs-popover-bottom > .arrow,
ngb-popover-window.bs-popover-top > .arrow {
  border-width: 11px;
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #ffffff;
  border-bottom-width: 0;
}

.popover-links > .popover-body .blue-button,
.popover-links > .popover-body .white-button {
  width: 47% !important;
}
.popover-links > .popover-body .blue-button {
  margin: 0px 0px 0px 10px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .delete-popover-container ngb-popover-window > .arrow:after {
    margin-left: -68px !important;
  }
  .carousel-modal .carousel-control-next {
    left: 94%;
  }
  app-mini-cart .cart-icons > ngb-popover-window {
    min-width: 448px !important;
    top: 15px !important;
    left: -38px;
    margin: 6px 0px 0px 0px !important;
  }
  .delete-popover-container ngb-popover-window {
    left: 79px !important;
  }
  app-statement-display .statement-dialog {
    width: 86% !important;
  }
  app-statement-display .statement-dialog .modal-header {
    width: 103%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .delete-popover-container ngb-popover-window > .arrow:after {
    margin-left: -87px !important;
  }
  .delete-popover-container ngb-popover-window {
    left: 92px !important;
  }
  cx-page-layout cx-page-slot.MiniCart {
    padding: 1px 24px 0px 0px !important;
  }
  app-mini-cart .cart-icons {
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .header cx-page-layout cx-page-slot.MiniCart {
    min-height: 1px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.MiniCart {
    padding: 9px 24px 0px 18px;
  }
  app-mini-cart .cart-icons > ngb-popover-window {
    min-width: 260px;
    left: -91px;
    top: 14px !important;
    margin: 7px 0px 0px -85px;
  }
  .cart-icons ngb-popover-window .popover-header .minicart-header {
    padding: 10px 11px 0px 16px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  cx-page-layout cx-page-slot.MiniCart {
    min-height: 1px;
  }
  .delete-rwd-ipad {
    left: 70%;
  }
  .carousel-modal .carousel-control-prev {
    margin: 0px;
  }
  app-statement-display .statement-dialog {
    width: 86% !important;
  }
  app-statement-display .statement-dialog .modal-header {
    width: 103%;
  }
  app-statement-display .statement-dialog .modal-body .statement-section {
    width: 34%;
    padding-left: 0px !important;
  }
  app-statement-display .statement-dialog .modal-body .account-section {
    width: 37%;
  }
}

@media (min-width: 992px) {
  cx-page-layout cx-page-slot.MiniCart {
    padding: 19px 34px 0px 0px;
  }
}

// saved cart

.popover-body .input-in-savedcart {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  display: inline-block;
  padding: 8px 12px;
  margin: 0px 0px 25px 0px;
}

.popover-header .rename-in-savedcart {
  padding: 10px 10px 10px -10px !important;
}

.restore-modal .modal-header {
  background: #edf6fb;
  height: 86px;
  padding: 10px;
  border: 0;
}

.restore-modal .modal-header .modal-title {
  color: #4191c3;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins-Medium", sans-serif;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.restore-modal .modal-body {
  padding: 20px;
  font-family: "NotoSans", sans-serif;
  position: relative;
  color: #5b646e;
  font-size: 12px;
}

.restore-modal .modal-dialog {
  max-width: 407px !important;
}

.popover-body .savedelt-cancel {
  background: #fff;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  color: #0057a6;
  opacity: 1;
  width: 41%;
  height: 32px;
  border: 2px solid #0057a6;
  outline: 0;
  margin-bottom: 9px;
}

.popover-body .savedelt-delete {
  background: #0057a6;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  color: #ffffff;
  opacity: 1;
  width: 41%;
  height: 32px;
  border: 0;
}

.delete-popover-container ngb-popover-window {
  max-width: 228px !important;
  border: 0px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 20%);
  margin: 0px 0px 0px -7px;
  width: 67%;
}

.delete-popover-container ngb-popover-window .popover-header {
  font-family: "NotoSansBold", sans-serif;
  color: #5b646e;
  padding: 20px 0px !important;
}

ngb-modal-window .confirm-order .clear-restore-scart {
  background: #0057a6;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  color: #ffffff;
  opacity: 1;
  width: 100%;
  height: 44px;
  border: 0;
  margin: 30px 0px 0px 0px;
}
ngb-modal-window .confirm-order .keepexisting-restore-cart {
  background: #fff;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  color: #0057a6;
  opacity: 1;
  width: 100%;
  height: 40px;
  border: 2px solid #0057a6;
  outline: 0;
  margin: 9px 0px 0px 0px;
}

.modal-content .restore-modal {
  position: relative;
  width: 100% !important;
  margin: 0px -26rem 0 0rem;
}
.modal-content .restore-header {
  margin-top: 30px !important;
}
.delete-popover-container ngb-popover-window.bs-popover-top > .arrow {
  border-top-color: #999;
  border-bottom-width: 0;
}
.savesuccess-msg .popover-body .cart-value-added {
  width: 146px;
}
ngb-popover-window .itemsincart-success-msg .popover-body .cart-value-added {
  width: 167px;
}
ngb-popover-window .popover-body .cart-value-added {
  border: 0px;
  position: relative;
  left: 0px;
  z-index: 1060;
  max-width: 276px;
  line-height: 1.3;
  span {
    width: 76%;
    text-align: left;
    font-family: "NotoSans", sans-serif !important;
    font-size: 12px !important;
    color: #5b646e !important;
    font-weight: bold !important;
    background-color: #ffffff;
  }
  .positive-green-icon {
    float: left;
    margin: 0px 0 0 0;
    padding: 0px 7px 0px 0px;
  }
}
.popover-payment-delete .popover-header {
  background-color: #ffffff;
  border: none;
  font-family: "NotoSansBold", sans-serif;
  color: #5b646e;
  font-size: 15px;
  text-align: center;
}
.popover-payment-delete .popover-body {
  width: 100%;
}

.savepopover .popover .arrow::after {
  transform: rotate(90deg);
  top: 100%;
  border: 2px solid black;
  display: block !important;
}
.custom-card-footer ngb-popover-window .popover-body {
  padding: 0px !important;
  border-color: white !important;
}

.popover-body .save-order {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  display: inline-block;
  padding: 8px 12px;
  margin: 0px 0px 25px 0px;
}

ngb-popover-window .popover-body .savethis-white-button {
  background: #fff;
  font-size: 0.875rem;
  font-family: "NotoSansBold", sans-serif;
  color: #0057a6;
  opacity: 1;
  width: 48.5%;
  height: 32px;
  padding: 5px;
  border: 2px solid #0057a6;
  outline: 0;
}
ngb-popover-window .popover-body .savethis-blue-button {
  background: #0057a6;
  font-size: 0.875rem;
  font-family: "NotoSansBold", sans-serif;
  color: #ffffff;
  opacity: 1;
  width: 48.5%;
  height: 32px;
  padding: 5px;
  border: 0;
}
.savethisorder-popclass {
  max-width: 318px;
}
.savedcartsuccess-msg {
  width: 242px !important;
  min-width: 222px !important;
  padding: 0px;
  max-height: 800px;
  top: 9px !important;
  border: none;
  left: 104px !important;
}
.savesuccess-msg {
  top: -8px !important;
  padding: 15px;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  border: solid 1px #fff;
  left: -18px !important;
}
.savesuccess-msg .bs-popover-top > .arrow {
  top: -10px;
}
.savedordermini-popover .popover-header {
  font-family: "NotoSansBold", sans-serif;
  color: #5b646e;
  padding: 20px 10px 5px 10px !important;
  margin: 0;
  font-size: 12px;
  background-color: #ffffff;
  border-bottom: none;
}
.minicart-std-popover {
  z-index: 9;
}

.savedordermini-popover .popover-body .save-order {
  width: 100%;
  height: 28px;
  margin-bottom: 24px;
  font-family: "NotoSans", sans-serif !important;
  font-size: 12px !important;
  color: #2a343f !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  display: inline-block;
  padding: 8px 12px;
}
.savedordermini-popover {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  min-width: 270px;
  max-width: 276px;
  border-radius: 6px;
  top: -8px !important;
  height: auto;
  z-index: 1060;
  min-height: 114px;
}
.savedordermini-popover .popover-body .cancel-white-button {
  background: #fff;
  font-size: 0.875rem;
  font-family: "NotoSansBold", sans-serif;
  color: #0057a6;
  opacity: 1;
  width: 47%;
  height: 32px;
  border: 2px solid #0057a6;
  outline: 0;
  padding: 5px;
}
.savedordermini-popover .popover-body .save-blue-button {
  background: #0057a6;
  font-size: 0.875rem;
  font-family: "NotoSansBold", sans-serif;
  color: #ffffff;
  opacity: 1;
  width: 47%;
  height: 32px;
  border: 0;
  margin: 0px 0px 0px 13px;
  padding: 5px;
}
app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-footer {
  padding: 20px 0px 0px 0px;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart {
  max-height: 240px;
  overflow-y: auto;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart::-webkit-scrollbar {
  width: 4px;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #313030;
  border-radius: 10px;
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-details-minicart::-webkit-scrollbar-thumb:hover {
  width: 4px;
  background-color: #000;
}

.itemsincart-msg {
  width: 200px !important;
  min-width: 200px !important;
  padding: 0px;
  max-height: 800px;
  top: 10px !important;
  border: none;
  left: 153px !important;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  cx-page-layout.SearchResultsGridPageTemplate
    .ProductLeftRefinements
    .product-refinement {
    display: flex;
    flex-flow: column;
    height: 282px;
    padding: 0px;
  }
}

.itemincart-success-msg {
  width: 193px !important;
  min-width: 184px !important;
  padding: 0px;
  max-height: 800px;
  top: 0px !important;
  border: none;
  left: 3px !important;
  border: solid 1px #fff;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
}
.minicart-std-popover .popover-header {
  margin: 0px !important;
}
.vol-Pricing ngb-popover-window.popover {
  max-width: 395px;
  border: 0px;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  padding: 15px 11px;
  width: 395px;
}
.vol-Pricing ngb-popover-window .popover-body {
  padding: 0px;
  color: #212529;
}
.vol-Pricing ngb-popover-window .popover-header {
  font-size: 12px;
  font-family: "NotoSansBold", sans-serif;
  color: #5b646e;
  text-align: left !important;
  padding: 0px;
  background-color: white;
  border-bottom: none;
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .vol-Pricing ngb-popover-window.popover {
    max-width: 270px;
    border: 0px;
    box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
    padding: 15px 11px;
    width: 270px;
    left: 150px !important;
  }
}
.email-popup-modal .modal-dialog {
  width: 40% !important;
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .email-popup-modal .modal-content {
    margin: 20px 30px 0px 30px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .email-popup-modal .modal-content {
    margin: 50px;
  }
}

app-mini-cart
  .cart-icons
  > ngb-popover-window
  .popover-body
  .cart-prod-list
  .cart-prod-details
  li
  .flu-minicart-price {
  padding: 0px 0px 0px 55px;
}

.delete-Div ngb-popover-window {
  left: 80px !important;
}
.delete-Div ngb-popover-window.bs-popover-top > .arrow {
  left: 34% !important;
}

.saveorder-incart .popover-header {
  padding: 20px 0px 10px 0px !important;
  text-align: left;
}

//carousel

@media (min-width: 1200px) {
  .carousel-indicators > li {
    width: 8px;
    height: 8px;
    margin: 12px 6px;
  }
}

.carousel-indicators > .active {
  background-color: #0057a6;
}

.externalsite-modal .modal-header {
  background-color: #edf6fb;
  padding: 0px 20px 20px 56px;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: #4191c3;
  text-align: center;
}

ngb-modal-window.externalsite-modal {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 69%;
}
.externalsite-modal .modal-content {
  width: 37%;
  float: left;
  margin: 0px 0 0 32%;
}
.externalsite-modal .blue-button {
  background: #0057a6;
  color: #ffffff;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  opacity: 1;
  width: 91%;
  margin: 0px 20px 0px 20px;
  height: 44px;
  border: 0;
}

.externalsite-modal .white-button {
  background: #fff;
  font-size: 14px;
  font-family: "NotoSansBold", sans-serif;
  color: #0057a6;
  opacity: 1;
  width: 91%;
  margin: 9px 20px 9px 20px !important;
  height: 40px;
  border: 2px solid #0057a6;
  outline: 0;
}
.res-Contact ngb-popover-window.popover {
  max-width: 270px;
  border: 0px;
  box-shadow: 0px 0px 10px rgb(23 114 183 / 20%);
  padding: 15px 11px;
  width: 270px;
  background-color: #5b646e;
  left: 140px !important;
}
.res-Contact ngb-popover-window .popover-body {
  padding: 0.5rem 0.75rem;
  color: white;
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .externalsite-modal .modal-content {
    width: 85%;
    left: 3%;
    top: 50px;
    margin: 0px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
  .externalsite-modal .modal-content {
    width: 85%;
    left: 3%;
    top: 50px;
    margin: 0px;
  }
  .delete-popover-container-rwd ngb-popover-window {
    width: 200%;
  }
  .delete-popover-container-rwd ngb-popover-window {
    box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 20%);
  }

  .delete-popover-container-rwd ngb-popover-window .arrow::after {
    border-top-color: #fff !important;
  }
  .delete-popover-container-rwd ngb-popover-window .popover-header {
    margin: 0;
    font-size: 14px;
    background-color: #ffffff;
    border-bottom: none;
    text-align: center;
  }

  .delete-popover-container-rwd ngb-popover-window .popover-body .blue-button {
    background: #0057a6;
    width: 45%;
    margin: 0px 0px 9px 20px;
    height: 40px;
  }

  .delete-popover-container-rwd ngb-popover-window .popover-body .white-button {
    width: 47%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .externalsite-modal .modal-content {
    width: 51% !important;
    left: 16.5%;
    top: 50px;
    margin: 0px 0 0 9% !important;
  }
  @media (max-width: 991.98px) {
    .carousel-modal cx-media img {
      max-width: -moz-fit-content;
      max-width: 100%;
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .externalsite-modal .modal-content {
    width: 59%;
    left: 20%;
    top: 50px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .externalsite-modal .modal-content {
    width: 41%;
    float: left;
    margin: 0px 0 0 30%;
  }
}

//carousel arrow.
.carousel-control-next {
  width: 25px;
  height: 50px;
  z-index: 5;
  position: absolute;
  top: 42%;
  left: 84.5%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  margin: 0 26px;
}
.carousel-control-next-icon {
  width: 12px;
  height: 12px;
  background: none;
  border: 0;
  outline: 0;
  border-top: 2px solid #0057a6;
  border-right: 2px solid #0057a6;
  cursor: pointer;
  position: relative;
  top: 20px;
  transform: rotate(45deg);
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
}
.carousel-control-prev {
  width: 25px;
  height: 50px;
  z-index: 5;
  position: absolute;
  top: 42%;
  left: 0.8%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin: 0 26px;
}

.carousel-control-prev-icon {
  width: 12px;
  height: 12px;
  background: none;
  border: 0;
  outline: 0;
  border-top: 2px solid #0057a6;
  border-right: 2px solid #0057a6;
  cursor: pointer;
  position: relative;
  top: 20px;
  transform: rotate(45deg);
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  transform: rotate(225deg);
}

//
.slick-next.slick-arrow {
  width: 25px;
  height: 50px;
  z-index: 5;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  margin: 0 26px;
}
.slick-prev:before,
.slick-next:before {
  content: "" !important;
  width: 12px;
  height: 12px;
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  border-top: 2px solid #0057a6;
  border-right: 2px solid #0057a6;
  cursor: pointer;
  position: absolute;
  left: 6px;
  top: 20px;
}
.slick-prev.slick-arrow {
  width: 25px;
  height: 50px;
  z-index: 5;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin: 0 26px;
}
.slick-prev:before,
.slick-next:before {
  content: "" !important;
  width: 12px;
  height: 12px;
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  border-top: 2px solid #0057a6;
  border-right: 2px solid #0057a6;
  cursor: pointer;
  position: absolute;
  left: 6px;
  top: 20px;
}
.slick-prev:before {
  transform: rotate(225deg);
}
.slick-next:before {
  transform: rotate(45deg);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .carousel-modal cx-media img {
    background: #f4f7f9;
    padding: 20px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    margin: 0px;
  }
  @media (max-width: 991.98px) {
    .carousel-modal cx-media img {
      max-width: -moz-fit-content;
      max-width: 100%;
    }
  }
}
