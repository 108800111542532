// Buttons
button,
a {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
}
.btn-popup {
  height: 44px;
  font-size: 14px;
}
.btn-active {
  background: #0057a6;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}
.btn-active-disable {
  opacity: 0.65;
}
.btn-cancel {
  border: 2px solid #0057a6;
  color: #0057a6;
  &:hover {
    color: #0057a6;
  }
}
.close-x-btn {
  background-image: url(/../assets/icons/close.png);
  background-repeat: no-repeat;
  height: 20px;
  cursor: pointer;
  background-size: 17px;
  width: 17px;
}

// Modal
.sanofi-modal-header {
  background: #edf6fb;
  min-height: 53px;
  position: relative;
  .close-x-btn {
    position: absolute;
    right: 20px;
  }
}
.sanofi-modal-body {
  .action-btns {
    display: flex;
    justify-content: space-around;
    .btn {
      min-width: 100px;
    }
  }
}
// Open link Modal
.open-link-modal .modal-content {
  max-width: 800px;
}
